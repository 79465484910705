<template>
  <div class="app-container">
    <div class="layout-content">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view></router-view>
      </keep-alive>
      <router-view v-else></router-view>
    </div>
    <div class="layout-footer">
      <van-tabbar v-model="active" fixed route safe-area-inset-bottom>
        <van-tabbar-item
          replace
          v-for="item in tabBarList"
          :key="item.name"
          :to="item.to"
          :name="item.name"
          >{{ item.title }}
          <template #icon="props">
            <img
              :src="props.active ? item.icon.active : item.icon.inactive"
            /> </template
        ></van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLayout',
  data() {
    return {
      active: 'home',
      tabBarList: [
        {
          title: '首页',
          name: 'home',
          to: {
            name: 'Home'
          },
          icon: {
            active: require('@/assets/icon/home-icon-a.png'),
            inactive: require('@/assets/icon/home-icon.png')
          }
        },
        {
          title: '我的',
          name: 'user',
          to: {
            name: 'User'
          },
          icon: {
            active: require('@/assets/icon/my-icon-a.png'),
            inactive: require('@/assets/icon/my-icon.png')
          }
        }
      ]
    }
  }
}
</script>

<style lang="less">
.layout-footer {
  img {
    width: 22px;
    height: 22px;
  }
}
</style>
